import React from "react";
import flatten from "lodash/flatten";
import find from "lodash/find";

export const categorizedRoutes = [
  {
    category: "Image",
    content: [
      {
        label: "Compress Image",
        path: "/compress-image",
        desc: "compress-image"
      }
    ]
  },
  {
    category: "SVG",
    content: [
      {
        label: "SVG to JSX",
        path: "/svg-to-jsx",
        packageName: "@svgr/core",
        packageUrl: "https://github.com/smooth-code/svgr",
        desc: "svg-to-jsx"
      },
      {
        label: "SVG to React Native",
        path: "/svg-to-react-native",
        packageName: "@svgr/core",
        packageUrl: "https://github.com/smooth-code/svgr",
        desc: "svg-to-react-native"
      }
    ]
  },
  {
    category: "HTML",
    content: [
      {
        label: "HTML to JSX",
        path: "/html-to-jsx",
        desc: "html-to-jsx"
      },
      {
        label: "HTML to Pug",
        path: "/html-to-pug",
        packageName: "html2pug",
        desc: "html-to-pug",
        packageUrl: "https://github.com/izolate/html2pug"
      }
    ]
  },
  {
    category: "JSON",
    content: [
      {
        label: "JSON to React PropTypes",
        path: "/json-to-proptypes",
        title: "开发者工具 | All important transforms at one place.",
        desc: "json-to-proptypes"
      },
      {
        label: "JSON to Flow",
        path: "/json-to-flow",
        desc: "json-to-flow"
      },
      {
        label: "JSON to GraphQL",
        path: "/json-to-graphql",
        desc: "json-to-graphql",
        packageName: "@walmartlabs/json-to-simple-graphql-schema",
        packageUrl:
          "https://github.com/walmartlabs/json-to-simple-graphql-schema"
      },
      {
        label: "JSON to TypeScript",
        path: "/json-to-typescript",
        desc: "json-to-typescript",
        packageUrl: "https://www.npmjs.com/package/json_typegen_wasm",
        packageName: "json_typegen_wasm"
      },
      {
        label: "JSON to MobX-State-Tree Model",
        path: "/json-to-mobx-state-tree",
        desc: "json-to-mobx-state-tree"
      },
      {
        label: "JSON to Sarcastic",
        path: "/json-to-sarcastic",
        desc: "json-to-sarcastic",
        packageName: "transform-json-types",
        packageUrl: "https://github.com/transform-it/transform-json-types"
      },
      {
        label: "JSON to io-ts",
        path: "/json-to-io-ts",
        desc: "json-to-io-ts",
        packageName: "transform-json-types",
        packageUrl: "https://github.com/transform-it/transform-json-types"
      },
      {
        label: "JSON to Rust Serde",
        path: "/json-to-rust-serde",
        desc: "json-to-rust-serde"
      },
      {
        label: "JSON to Mongoose Schema",
        path: "/json-to-mongoose",
        packageName: "generate-schema",
        desc: "json-to-mongoose",
        packageUrl: "https://github.com/nijikokun/generate-schema"
      },
      {
        label: "JSON to Big Query Schema",
        path: "/json-to-big-query",
        desc: "json-to-big-query",
        packageName: "generate-schema",
        packageUrl: "https://github.com/nijikokun/generate-schema"
      },
      {
        label: "JSON to MySQL",
        path: "/json-to-mysql",
        desc: "json-to-mysql",
        packageName: "generate-schema",
        packageUrl: "https://github.com/nijikokun/generate-schema"
      },
      {
        label: "JSON to Scala Case Class",
        path: "/json-to-scala-case-class",
        desc: "json-to-scala-case-class"
      },
      {
        label: "JSON to Go Struct",
        path: "/json-to-go",
        packageName: "json-to-go",
        desc: "json-to-go",
        packageUrl: "https://github.com/mholt/json-to-go"
      },
      {
        label: "JSON to Go Bson",
        path: "/json-to-go-bson",
        desc: "json-to-go-bson"
      },
      {
        label: "JSON to YAML",
        path: "/json-to-yaml",
        packageName: "json2yaml",
        desc: "json-to-yaml",
        packageUrl: "https://github.com/jeffsu/json2yaml"
      },
      {
        label: "JSON to JSDoc",
        path: "/json-to-jsdoc",
        desc: "json-to-jsdoc"
      },
      {
        label: "JSON to Kotlin",
        path: "/json-to-kotlin",
        desc: "json-to-kotlin",
        packageUrl: "https://www.npmjs.com/package/json_typegen_wasm",
        packageName: "json_typegen_wasm"
      },
      {
        label: "JSON to Java",
        path: "/json-to-java",
        desc: "json-to-java",
        packageUrl: "https://www.npmjs.com/package/json_typegen_wasm",
        packageName: "json_typegen_wasm"
      },
      {
        label: "JSON to JSON Schema",
        path: "/json-to-json-schema",
        desc: "json-to-json-schema",
        packageUrl: "https://www.npmjs.com/package/json_typegen_wasm",
        packageName: "json_typegen_wasm"
      },
      {
        label: "JSON to TOML",
        path: "/json-to-toml",
        desc: "json-to-toml",
        packageUrl: "https://www.npmjs.com/package/@iarna/toml",
        packageName: "@iarna/toml"
      },
      {
        label: "JSON to Zod Schema",
        path: "/json-to-zod",
        desc: "json-to-zod",
        packageUrl: "https://www.npmjs.com/package/json-to-zod",
        packageName: "json-to-zod"
      }
    ]
  },
  {
    category: "JSON Schema",
    content: [
      {
        label: "JSON Schema to TypeScript",
        path: "/json-schema-to-typescript",
        desc: "json-schema-to-typescript",
        packageName: "json-schema-to-typescript",
        packageUrl: "https://github.com/bcherny/json-schema-to-typescript"
      },
      {
        label: "JSON Schema to OpenAPI Schema",
        path: "/json-schema-to-openapi-schema",
        desc: "json-schema-to-openapi-schema",
        packageName: "json-schema-to-openapi-schema",
        packageUrl:
          "https://github.com/openapi-contrib/json-schema-to-openapi-schema"
      },
      {
        label: "JSON Schema to Protobuf",
        path: "/json-schema-to-protobuf",
        desc: "json-schema-to-protobuf",
        packageName: "jsonschema-protobuf",
        packageUrl: "https://github.com/okdistribute/jsonschema-protobuf"
      },
      {
        label: "JSON Schema to Zod Schema",
        path: "/json-schema-to-zod",
        desc: "json-schema-to-zod",
        packageName: "json-schema-to-zod",
        packageUrl: "https://www.npmjs.com/package/json-schema-to-zod"
      }
    ]
  },
  {
    category: "CSS",
    content: [
      {
        label: "CSS to JS Objects",
        path: "/css-to-js",
        desc: "css-to-js",
        packageName: "transform-css-to-js",
        packageUrl: "https://github.com/transform-it/transform-css-to-js"
      },
      {
        label: "CSS to template literal",
        path: "/object-styles-to-template-literal",
        desc: "object-styles-to-template-literal",
        packageUrl:
          "https://github.com/satya164/babel-plugin-object-styles-to-template",
        packageName: "babel-plugin-object-styles-to-template"
      },
      {
        label: "CSS to TailwindCSS",
        path: "/css-to-tailwind",
        desc: "css-to-tailwind",
        packageUrl: "https://github.com/Jackardios/css-to-tailwindcss",
        packageName: "css-to-tailwindcss"
      },
      {
        label: "Less to CSS",
        path: "/less-to-css",
        desc: "less-to-css"
      },
      {
        label: "Scss to CSS",
        desc: "scss-to-css",
        path: "/scss-to-css"
      },
      {
        label: "Sass to CSS",
        desc: "sass-to-css",
        path: "/sass-to-css"
      }
    ]
  },
  {
    category: "JavaScript",
    content: [
      {
        label: "JavaScript to JSON",
        path: "/js-object-to-json",
        desc: "js-object-to-json"
      }
    ]
  },
  {
    category: "GraphQL",
    content: [
      {
        label: "GraphQL to TypeScript",
        path: "/graphql-to-typescript",
        desc: "graphql-to-typescript"
      },
      {
        label: "GraphQL to Flow",
        path: "/graphql-to-flow",
        desc: "graphql-to-flow"
      },
      {
        label: "GraphQL to JAVA",
        path: "/graphql-to-java",
        desc: "graphql-to-java"
      },
      {
        label: "GraphQL to Resolvers Signature",
        path: "/graphql-to-resolvers-signature",
        desc: "graphql-to-resolvers-signature"
      },

      {
        label: "GraphQL to Introspection JSON",
        path: "/graphql-to-introspection-json",
        desc: "graphql-to-introspection-json"
      },

      {
        label: "GraphQL to Schema AST",
        path: "/graphql-to-schema-ast",
        desc: "graphql-to-schema-ast"
      },
      {
        label: "GraphQL to Fragment Matcher",
        path: "/graphql-to-fragment-matcher",
        desc: "graphql-to-fragment-matcher"
      },
      {
        label: "GraphQL to Components",
        path: "/graphql-to-components",
        desc: "graphql-to-components"
      },
      {
        label: "GraphQL to TypeScript MongoDB",
        path: "/graphql-to-typescript-mongodb",
        desc: "graphql-to-typescript-mongodb"
      }
    ].map(x => ({
      ...x,
      packageUrl: "https://github.com/dotansimha/graphql-code-generator",
      packageName: "graphql-code-generator"
    }))
  },
  {
    category: "JSON-LD",
    content: [
      {
        label: "JSON-LD to N-Quads",
        path: "/jsonld-to-nquads",
        desc: "jsonld-to-nquads"
      },
      {
        label: "JSON-LD to Expanded",
        path: "/jsonld-to-expanded",
        desc: "jsonld-to-expanded"
      },
      {
        label: "JSON-LD to Compacted",
        path: "/jsonld-to-compacted",
        desc: "jsonld-to-compacted"
      },
      {
        label: "JSON-LD to Flattened",
        path: "/jsonld-to-flattened",
        desc: "jsonld-to-flattened"
      },
      {
        label: "JSON-LD to Framed",
        path: "/jsonld-to-framed",
        desc: "jsonld-to-framed"
      },
      {
        label: "JSON-LD to Normalized",
        path: "/jsonld-to-normalized",
        desc: "jsonld-to-normalized"
      }
    ].map(x => ({
      ...x,
      packageName: "jsonld",
      packageUrl: "https://github.com/digitalbazaar/jsonld.js"
    }))
  },
  {
    category: "TypeScript",
    content: [
      {
        label: "TypeScript to Flow",
        path: "/typescript-to-flow",
        desc: "typescript-to-flow",
        packageName: "flowgen",
        packageUrl: "https://github.com/joarwilk/flowgen"
      },
      {
        label: "TypeScript to TypeScript Declaration",
        path: "/typescript-to-typescript-declaration",
        desc: "typescript-to-typescript-declaration"
      },
      {
        label: "TypeScript to JSON Schema",
        path: "/typescript-to-json-schema",
        desc: "typescript-to-json-schema",
        packageName: "ts-json-schema-generator",
        packageUrl: "https://github.com/vega/ts-json-schema-generator"
      },
      {
        label: "TypeScript to plain JavaScript",
        path: "/typescript-to-javascript",
        desc: "typescript-to-javascript"
      },
      {
        label: "TypeScript to Zod Schema",
        path: "/typescript-to-zod",
        desc: "typescript-to-zod",
        packageName: "ts-to-zod",
        packageUrl: "https://www.npmjs.com/package/ts-to-zod"
      }
    ]
  },
  {
    category: "Flow",
    iconName: "",
    content: [
      {
        label: "Flow to TypeScript",
        path: "/flow-to-typescript",
        desc: "flow-to-typescript"
      },
      {
        label: "Flow to TypeScript Declaration",
        path: "/flow-to-typescript-declaration",
        desc: "flow-to-typescript-declaration"
      },
      {
        label: "Flow to plain JavaScript",
        path: "/flow-to-javascript",
        desc: "flow-to-javascript"
      }
    ]
  },
  {
    category: "Others",
    iconName: "",
    content: [
      {
        label: "XML to JSON",
        path: "/xml-to-json",
        desc: "xml-to-json",
        packageName: "xml-js",
        packageUrl: "https://github.com/nashwaan/xml-js"
      },
      {
        label: "YAML to JSON",
        path: "/yaml-to-json",
        desc: "yaml-to-json",
        packageName: "yaml",
        packageUrl: "https://github.com/tj/js-yaml"
      },
      {
        label: "YAML to TOML",
        path: "/yaml-to-toml",
        desc: "yaml-to-toml"
      },
      {
        label: "Markdown to HTML",
        path: "/markdown-to-html",
        packageName: "markdown",
        packageUrl: "https://github.com/evilstreak/markdown-js",
        desc: "markdown-to-html"
      },
      {
        label: "TOML to JSON",
        path: "/toml-to-json",
        packageUrl: "https://www.npmjs.com/package/@iarna/toml",
        packageName: "@iarna/toml",
        desc: "toml-to-json"
      },
      {
        label: "TOML to YAML",
        path: "/toml-to-yaml",
        desc: "toml-to-yaml"
      },
      {
        label: "Cadence to Go",
        path: "/cadence-to-go",
        desc: "cadence-to-go"
      },
      {
        label: "Random Password Generator",
        path: "/password",
        desc: "password"
      }
    ]
  }
];

export interface Route {
  path: string;
  label: string;
  desc: string;
}

export const routes = flatten(
  categorizedRoutes.map(a =>
    // @ts-ignore
    a.content.map(x => {
      const cat = a.category.toLowerCase();
      const _label =
        cat !== "others" && cat.startsWith("to")
          ? `${a.category} ${x.label}`
          : x.label;
      return {
        ...x,
        category: a.category,
        searchTerm: _label,
        desc:
          x.desc || `An online playground to convert ${_label}, from douni.one`
      };
    })
  )
);

const navigatorMenus: Record<string, any> = {};
categorizedRoutes.forEach(item => {
  const cat = item.category?.toLowerCase();
  item.content = item.content.map(x => {
    const cat = item.category.toLowerCase();
    const _label =
      cat !== "others" && cat.startsWith("to")
        ? `${item.category} ${x.label}`
        : x.label;
    return {
      ...x,
      desc:
        x.desc || `An online playground to convert ${_label}, from douni.one`
    };
  });
  if (cat.includes("json")) {
    if (!navigatorMenus["json-tool"]) {
      navigatorMenus["json-tool"] = [];
    }
    navigatorMenus["json-tool"] = navigatorMenus["json-tool"].concat(
      item.content
    );
  }
  if (
    cat.includes("javascript") ||
    cat.includes("typescript") ||
    cat.includes("flow")
  ) {
    if (!navigatorMenus["javascript-tool"]) {
      navigatorMenus["javascript-tool"] = [];
    }
    navigatorMenus["javascript-tool"] = navigatorMenus[
      "javascript-tool"
    ].concat(item.content);
  }
  if (
    cat.includes("html") ||
    cat.includes("css") ||
    cat.includes("svg") ||
    cat.includes("image")
  ) {
    if (!navigatorMenus["html-tool"]) {
      navigatorMenus["html-tool"] = [];
    }
    navigatorMenus["html-tool"] = navigatorMenus["html-tool"].concat(
      item.content
    );
  }

  if (cat.includes("graphql")) {
    if (!navigatorMenus["Graphql"]) {
      navigatorMenus["Graphql"] = [];
    }
    navigatorMenus["Graphql"] = navigatorMenus["Graphql"].concat(item.content);
  }
  if (cat.includes("others")) {
    if (!navigatorMenus["other-tool"]) {
      navigatorMenus["other-tool"] = [];
    }
    navigatorMenus["other-tool"] = navigatorMenus["other-tool"].concat(
      item.content
    );
  }
});
export { navigatorMenus };
export function activeRouteData(pathname): {
  label: string;
  path: string;
  searchTerm: string;
  desc: string;
  packageUrl?: string;
  packageName?: string;
} {
  return find(routes, o => o.path === pathname);
}
